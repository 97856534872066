import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import get from 'lodash/get'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Contacts from '../components/Contacts'

export const query = graphql`
  query ContactQuery {
    prismic {
      content: allContacts {
        edges {
          node {
            address
            contact_methods
            text
            kicker
            title
            socials {
              social_network {
                ... on PRISMIC_Social_network {
                  name
                  link {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
            image
            image_full: imageSharp {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`

const ContactPage = props => {
  const node = get(props, 'data.prismic.content.edges[0].node') || {}
  const { title } = node

  return (
    <Layout>
      {title && <SEO title={RichText.asText(title)} />}
      <Contacts data={node} />
    </Layout>
  )
}

export default ContactPage
