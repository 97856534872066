import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import PinIcon from '../../images/svg/pin.svg'
import ChatIcon from '../../images/svg/chat.svg'
import HeroBlock from '../Hero'
import Image from '../Image'
import SocialNetworkIcon from '../SocialNetworkIcon'
import './style.css'

const Contacts = ({ data }) => {
  const {
    socials,
    address,
    contact_methods,
    text,
    kicker,
    image_full,
    image,
  } = data

  return (
    <div>
      <HeroBlock
        node={{
          kicker,
          content: text,
        }}
      />
      <div className="contacts">
        <div className="contacts__i">
          <div className="contacts__address">
            <div className="contacts__place">
              <PinIcon className="contacts__place__icon" />
              <div className="contacts__place__text">
                {RichText.render(address)}
              </div>
            </div>
            <div className="contacts__place">
              <ChatIcon className="contacts__place__icon" />
              <div className="contacts__place__text">
                {RichText.render(contact_methods)}
              </div>
            </div>
          </div>
          {socials && (
            <div className="contacts__social">
              {socials.map(item => (
                <a
                  href={item.social_network.link.url}
                  key={item.social_network.link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contacts__social__item link-simple"
                >
                  <SocialNetworkIcon name={item.social_network.name} />
                </a>
              ))}
            </div>
          )}
          <div className="contacts__image">
            {/* eslint-disable-next-line react/prop-types */}
            {image && <Image fluid={image_full} image={image} />}
          </div>
        </div>
      </div>
    </div>
  )
}

Contacts.propTypes = {
  data: PropTypes.shape({
    socials: PropTypes.arrayOf({
      item: {
        social_network: {
          type: PropTypes.string,
          text: PropTypes.string,
          spans: PropTypes.array,
        },
      },
    }),
    address: PropTypes.array,
    contact_methods: PropTypes.string,
    text: PropTypes.string,
    kicker: PropTypes.string,
    image: {
      dimensions: {
        height: PropTypes.number,
        width: PropTypes.number,
      },
      alt: PropTypes.string,
      copyright: PropTypes.string,
      url: PropTypes.string,
    },
    image_full: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
  }),
}

Contacts.defaultProps = {
  data: null,
}

export default Contacts
